@import "styles/core.scss";
.breadcrumbItem {
  white-space: nowrap;
  display: inline-block;
  @include text-100;
  color: var(--color-text-primary);
}

.breadcrumbItemLink {
  @include text-100;
  color: var(--color-text-primary);
  text-underline-offset: var(--space-50);

  @include focusOrHover {
    text-decoration: underline;
  }
}

.lastBreadcrumbItem {
  @include text-100-bold;
  color: var(--color-text-primary);
}

.divider {
  margin-left: var(--space-100);
  margin-right: var(--space-100);
  text-decoration: none !important;
}
